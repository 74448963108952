import React, { useState } from 'react';
import { Typography, TextField, Button, Box, Paper, MenuItem } from '@mui/material';
import { keyframes } from '@emotion/react';

// Define keyframes for animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const volumeUnits = [
    { label: 'Liters', value: 'liters' },
    { label: 'Milliliters', value: 'milliliters' },
    { label: 'Cups', value: 'cups' },
    { label: 'Gallons', value: 'gallons' },
    { label: 'Quarts', value: 'quarts' },
    { label: 'Pints', value: 'pints' },
    { label: 'Fluid Ounces', value: 'fluid ounces' },
];

const conversionFactors = {
    liters: 1,
    milliliters: 1000,
    cups: 4.22675,
    gallons: 0.264172,
    quarts: 1.05669,
    pints: 2.11338,
    "fluid ounces": 33.814,
};

// Fun facts for the component
const funFacts = [
    "Did you know? A gallon of water weighs around 8.34 pounds or 3.78 kilograms.",
    "In baking, precise volume measurements are crucial for consistent results.",
    "The average adult human body contains about 37 liters of water.",
    "The largest cup in the world holds over 18,000 liters and is located in Saudi Arabia.",
    "A milliliter is equivalent to one-thousandth of a liter.",
    "In the U.S., a 'cup' is considered to be 240 milliliters in volume."
];

const getRandomFunFact = () => {
    const randomIndex = Math.floor(Math.random() * funFacts.length);
    return funFacts[randomIndex];
};

// Volume converter function
const convertVolume = (value, fromUnit, toUnit) => {
    const inLiters = value / conversionFactors[fromUnit];  // Convert the input to liters
    return inLiters * conversionFactors[toUnit];  // Convert from liters to the target unit
};

const VolumeConverter = () => {
    const [inputValue, setInputValue] = useState('');
    const [fromUnit, setFromUnit] = useState('liters');
    const [toUnit, setToUnit] = useState('milliliters');
    const [result, setResult] = useState('');
    const [funFact, setFunFact] = useState('');
    const [error, setError] = useState('');

    const handleConvert = () => {
        const value = parseFloat(inputValue);
        if (!isNaN(value)) {
            setError('');
            const convertedValue = convertVolume(value, fromUnit, toUnit);
            setResult(`${convertedValue.toFixed(2)} ${toUnit}`);
            setFunFact(getRandomFunFact());
        } else {
            setError('Invalid input. Please enter a numeric value.');
            setResult('');
            setFunFact('');
        }
    };

    return (
        <Box
            component="section"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '100vh',
                padding: '40px',
                backgroundColor: '#f4f6f9',
                animation: `${fadeIn} 1s ease-out`,
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    padding: '30px',
                    maxWidth: '500px',
                    width: '100%',
                    textAlign: 'center',
                    backgroundColor: '#ffffff',
                    borderRadius: '12px',
                    animation: `${fadeIn} 1s ease-out`,
                }}
            >
                <Typography
                    variant="h4"
                    component="h1"
                    gutterBottom
                    sx={{
                        fontFamily: 'Space Grotesk',
                        fontWeight: 700,
                        color: '#4179ff',
                    }}
                >
                    Volume Converter
                </Typography>
                <Typography
                    variant="body1"
                    component="p"
                    gutterBottom
                    sx={{
                        fontFamily: 'Space Grotesk',
                        fontWeight: 400,
                        color: '#52575C',
                        marginBottom: '20px',
                    }}
                >
                    Convert between liters, milliliters, cups, gallons, and more!
                </Typography>

                {/* Input for the volume value */}
                <TextField
                    label="Enter Value"
                    placeholder="e.g., 1.5"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    fullWidth
                    sx={{
                        marginBottom: '20px',
                        '& input': {
                            fontFamily: 'Space Grotesk',
                            fontWeight: 400,
                            color: '#26274B',
                        },
                    }}
                />

                {/* Select the unit to convert from */}
                <TextField
                    select
                    label="From Unit"
                    value={fromUnit}
                    onChange={(e) => setFromUnit(e.target.value)}
                    fullWidth
                    sx={{ marginBottom: '20px' }}
                >
                    {volumeUnits.map((unit) => (
                        <MenuItem key={unit.value} value={unit.value}>
                            {unit.label}
                        </MenuItem>
                    ))}
                </TextField>

                {/* Select the unit to convert to */}
                <TextField
                    select
                    label="To Unit"
                    value={toUnit}
                    onChange={(e) => setToUnit(e.target.value)}
                    fullWidth
                    sx={{ marginBottom: '20px' }}
                >
                    {volumeUnits.map((unit) => (
                        <MenuItem key={unit.value} value={unit.value}>
                            {unit.label}
                        </MenuItem>
                    ))}
                </TextField>

                {/* Convert button */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConvert}
                    fullWidth
                    sx={{
                        padding: '10px',
                        fontFamily: 'Space Grotesk',
                        fontWeight: 600,
                        backgroundColor: '#4179ff',
                        '&:hover': {
                            backgroundColor: '#3c3f58',
                        },
                    }}
                >
                    Convert
                </Button>

                {/* Display result */}
                {error && (
                    <Typography
                        variant="body2"
                        component="p"
                        sx={{
                            marginTop: '20px',
                            fontFamily: 'Space Grotesk',
                            fontWeight: 400,
                            color: '#ff4d4d',
                        }}
                    >
                        Error: {error}
                    </Typography>
                )}

                {result && (
                    <Typography
                        variant="h6"
                        component="p"
                        sx={{
                            marginTop: '20px',
                            fontFamily: 'Space Grotesk',
                            fontWeight: 500,
                            color: '#26274B',
                        }}
                    >
                        Result: {result}
                    </Typography>
                )}
            </Paper>

            {/* Fun Fact */}
            {funFact && (
                <Paper
                    elevation={3}
                    sx={{
                        padding: '30px',
                        marginTop: '40px',
                        maxWidth: '500px',
                        width: '100%',
                        textAlign: 'left',
                        backgroundColor: '#ffffff',
                        borderRadius: '12px',
                    }}
                >
                    <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom
                        sx={{
                            fontFamily: 'Space Grotesk',
                            fontWeight: 600,
                            color: '#26274B',
                        }}
                    >
                        Fun Fact
                    </Typography>
                    <Typography
                        variant="body1"
                        component="p"
                        sx={{
                            fontFamily: 'Space Grotesk',
                            fontWeight: 400,
                            color: '#52575C',
                        }}
                    >
                        {funFact}
                    </Typography>
                </Paper>
            )}

            {/* How to Use and Benefits Section */}
            <Paper
                elevation={3}
                sx={{
                    padding: '30px',
                    marginTop: '40px',
                    maxWidth: '500px',
                    width: '100%',
                    textAlign: 'left',
                    backgroundColor: '#ffffff',
                    borderRadius: '12px',
                }}
            >
                <Typography
                    variant="h5"
                    component="h2"
                    gutterBottom
                    sx={{
                        fontFamily: 'Space Grotesk',
                        fontWeight: 600,
                        color: '#26274B',
                    }}
                >
                    How to Use
                </Typography>
                <Typography
                    variant="body1"
                    component="p"
                    sx={{
                        fontFamily: 'Space Grotesk',
                        fontWeight: 400,
                        color: '#52575C',
                    }}
                >
                    1. Enter a volume value (e.g., 1.5) in the input field.
                    <br />
                    2. Select the unit you're converting from and the unit you're converting to.
                    <br />
                    3. Click on "Convert" to get the result.
                </Typography>
            </Paper>

            <Paper
                elevation={3}
                sx={{
                    padding: '30px',
                    marginTop: '40px',
                    maxWidth: '500px',
                    width: '100%',
                    textAlign: 'left',
                    backgroundColor: '#ffffff',
                    borderRadius: '12px',
                }}
            >
                <Typography
                    variant="h5"
                    component="h2"
                    gutterBottom
                    sx={{
                        fontFamily: 'Space Grotesk',
                        fontWeight: 600,
                        color: '#26274B',
                    }}
                >
                    Benefits
                </Typography>
                <Typography
                    variant="body1"
                    component="p"
                    sx={{
                        fontFamily: 'Space Grotesk',
                        fontWeight: 400,
                        color: '#52575C',
                    }}
                >
                    1. Accuracy: Avoid mistakes in measurements when cooking, baking, or doing scientific work.
                    <br />
                    2. Versatility: Convert between a variety of units like liters, cups, and gallons.
                    <br />
                    3. Convenience: Quick and easy conversions for everyday use.
                </Typography>
            </Paper>
        </Box>
    );
};

export default VolumeConverter;
