// src/components/SpeedConverter.js
import React, { useState, useCallback } from 'react';
import { Typography, TextField, Button, Box, Paper, MenuItem } from '@mui/material';
import { keyframes } from '@emotion/react';

// Define keyframes for animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

// Speed units and conversion factors
const speedUnits = [
  { label: 'Kilometers per hour (kph)', value: 'kph' },
  { label: 'Miles per hour (mph)', value: 'mph' },
  { label: 'Meters per second (mps)', value: 'mps' },
  { label: 'Feet per second (fps)', value: 'fps' },
];

const conversionFactors = {
  kph: 1,
  mph: 1.60934,
  mps: 3.6,
  fps: 1.09728,
};

// Fun facts about speed
const funFacts = [
  "Cheetahs are the fastest land animals, reaching speeds up to 120 kph!",
  "The speed of sound in air is approximately 343 meters per second.",
  "The world record for the fastest car speed is over 490 kph.",
  "A Boeing 747 cruises at around 900 kph."
];

const getRandomFunFact = () => funFacts[Math.floor(Math.random() * funFacts.length)];

// Speed converter function
const convertSpeed = (value, fromUnit, toUnit) => {
  const inKph = value / conversionFactors[fromUnit];  // Convert the input to kph
  return inKph * conversionFactors[toUnit];  // Convert from kph to the target unit
};

const SpeedConverter = () => {
  const [inputValue, setInputValue] = useState('');
  const [fromUnit, setFromUnit] = useState('kph');
  const [toUnit, setToUnit] = useState('mph');
  const [result, setResult] = useState('');
  const [funFact, setFunFact] = useState('');
  const [error, setError] = useState('');

  const handleConvert = useCallback(() => {
    const value = parseFloat(inputValue);
    if (!isNaN(value)) {
      setError('');
      const convertedValue = convertSpeed(value, fromUnit, toUnit);
      setResult(`${convertedValue.toFixed(2)} ${toUnit}`);
      setFunFact(getRandomFunFact());
    } else {
      setError('Invalid input. Please enter a numeric value.');
      setResult('');
      setFunFact('');
    }
  }, [inputValue, fromUnit, toUnit]);

  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        padding: '40px',
        backgroundColor: '#f4f6f9',
        animation: `${fadeIn} 1s ease-out`,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: '30px',
          maxWidth: '500px',
          width: '100%',
          textAlign: 'center',
          backgroundColor: '#ffffff',
          borderRadius: '12px',
          animation: `${fadeIn} 1s ease-out`,
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            fontFamily: 'Space Grotesk',
            fontWeight: 700,
            color: '#4179ff',
          }}
        >
          Speed Converter
        </Typography>
        <Typography
          variant="body1"
          component="p"
          gutterBottom
          sx={{
            fontFamily: 'Space Grotesk',
            fontWeight: 400,
            color: '#52575C',
            marginBottom: '20px',
          }}
        >
          Convert between kilometers per hour, miles per hour, meters per second, and more!
        </Typography>

        {/* Input for the speed value */}
        <TextField
          label="Enter Speed"
          placeholder="e.g., 60"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          fullWidth
          sx={{
            marginBottom: '20px',
            '& input': {
              fontFamily: 'Space Grotesk',
              fontWeight: 400,
              color: '#26274B',
            },
          }}
        />

        {/* Select the unit to convert from */}
        <TextField
          select
          label="From Unit"
          value={fromUnit}
          onChange={(e) => setFromUnit(e.target.value)}
          fullWidth
          sx={{ marginBottom: '20px' }}
        >
          {speedUnits.map((unit) => (
            <MenuItem key={unit.value} value={unit.value}>
              {unit.label}
            </MenuItem>
          ))}
        </TextField>

        {/* Select the unit to convert to */}
        <TextField
          select
          label="To Unit"
          value={toUnit}
          onChange={(e) => setToUnit(e.target.value)}
          fullWidth
          sx={{ marginBottom: '20px' }}
        >
          {speedUnits.map((unit) => (
            <MenuItem key={unit.value} value={unit.value}>
              {unit.label}
            </MenuItem>
          ))}
        </TextField>

        {/* Convert button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleConvert}
          fullWidth
          sx={{
            padding: '10px',
            fontFamily: 'Space Grotesk',
            fontWeight: 600,
            backgroundColor: '#4179ff',
            '&:hover': {
              backgroundColor: '#3c3f58',
            },
          }}
        >
          Convert
        </Button>

        {/* Display result or error */}
        {error && (
          <Typography
            variant="body2"
            component="p"
            sx={{
              marginTop: '20px',
              fontFamily: 'Space Grotesk',
              fontWeight: 400,
              color: '#ff4d4d',
            }}
          >
            Error: {error}
          </Typography>
        )}

        {result && (
          <Typography
            variant="h6"
            component="p"
            sx={{
              marginTop: '20px',
              fontFamily: 'Space Grotesk',
              fontWeight: 500,
              color: '#26274B',
            }}
          >
            Result: {result}
          </Typography>
        )}
      </Paper>

      {/* Fun Fact */}
      {funFact && (
        <Paper
          elevation={3}
          sx={{
            padding: '30px',
            marginTop: '40px',
            maxWidth: '500px',
            width: '100%',
            textAlign: 'left',
            backgroundColor: '#ffffff',
            borderRadius: '12px',
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{
              fontFamily: 'Space Grotesk',
              fontWeight: 600,
              color: '#26274B',
            }}
          >
            Fun Fact
          </Typography>
          <Typography
            variant="body1"
            component="p"
            sx={{
              fontFamily: 'Space Grotesk',
              fontWeight: 400,
              color: '#52575C',
            }}
          >
            {funFact}
          </Typography>
        </Paper>
      )}

      {/* How to Use Section */}
      <Paper
        elevation={3}
        sx={{
          padding: '30px',
          marginTop: '40px',
          maxWidth: '500px',
          width: '100%',
          textAlign: 'left',
          backgroundColor: '#ffffff',
          borderRadius: '12px',
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          gutterBottom
          sx={{
            fontFamily: 'Space Grotesk',
            fontWeight: 600,
            color: '#26274B',
          }}
        >
          How to Use
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{
            fontFamily: 'Space Grotesk',
            fontWeight: 400,
            color: '#52575C',
          }}
        >
          1. Enter a speed value (e.g., 60) in the input field.
          <br />
          2. Select the unit you're converting from and the unit you're converting to.
          <br />
          3. Click on "Convert" to get the result.
        </Typography>
      </Paper>
    </Box>
  );
};

export default SpeedConverter;
