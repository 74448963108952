import React, { Suspense } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

// Lazily load tools data to reduce initial load time
import toolsData from '../assets/toolsData.json';
import Loading from './Loading';

const ToolsSection = React.memo(() => {
    return (
        <Suspense fallback={<Loading/>}>
            <Container
                maxWidth={false}
                disableGutters
                sx={{
                    backgroundColor: '#f2f2fc',
                    width: '100%',
                    py: 3, // Padding top and bottom
                }}
            >
                <Box sx={{ width: '90%', mx: 'auto', textAlign: 'center' }}>
                    <Grid container spacing={4} justifyContent="center">
                        {toolsData.map((tool, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Link to={tool.route} style={{ textDecoration: 'none' }}>
                                    <Card
                                        sx={{
                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Reduced shadow for performance
                                            borderRadius: '8px',
                                            transition: 'transform 0.2s ease-in-out', // Slightly reduced transition time
                                            overflow: 'hidden',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                transform: 'scale(1.03)', // Reduce scale on hover for performance
                                            },
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                variant="h6"
                                                component="div"
                                                sx={{
                                                    fontFamily: 'Space Grotesk',
                                                    fontWeight: 600,
                                                    textAlign: 'start',
                                                    mb: 1.5, // Margin bottom between title and description
                                                }}
                                            >
                                                {tool.title}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                sx={{
                                                    fontFamily: 'Space Grotesk',
                                                    fontWeight: 300,
                                                    textAlign: 'start',
                                                    mb: 2, // Margin bottom between description and button
                                                }}
                                            >
                                                {tool.description}
                                            </Typography>
                                            <Box textAlign="start">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{
                                                        fontFamily: 'Space Grotesk',
                                                        fontWeight: 300,
                                                        textTransform: 'none', // Prevent capitalization of text
                                                    }}
                                                >
                                                    Try Out
                                                </Button>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </Suspense>
    );
});

export default ToolsSection;
